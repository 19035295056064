/* Custom Code Starts Here */

// Default DataTable Options
$.fn.DataTable.ext.pager.numbers_length = 5;
window.defMainDtOpts = {
    scrollCollapse: false,
    deferRender: true,
    serverSide: true,
    info: false,
    responsive: false,
    autoWidth: false,
    processing: true,
    dom: 'st',
    pageLength: 25,
    scroller: {
        serverWait: 25,
        displayBuffer: 25,
    },
    select: 'single',
    keys: {keys: [13, 38, 40]},
    rowId: 'id',
    columnDefs: [{'targets': '_all', 'searchable': false}],
    createdRow(row, data, dataIndex) {
        if (data.id) {
            $(row).data('id', data.id);
        }
    },
};

window.defSecDtOpts = {
    searching: false,
    ordering: false,
    scrollCollapse: false,
    deferRender: true,
    serverSide: false,
    info: false,
    responsive: true,
    autoWidth: false,
    processing: true,
    paging: false,
    dom: 'st',
    createdRow(row, data, dataIndex) {
        if (data.id) {
            $(row).data('id', data.id);
        }
    },
};

$.fn.dataTable.Api.register( 'defMainEvents()', function () {
    return this.on('init', function (e, settings, json) {
        let $table = $(e.target);

        if ($table.is(':visible')) {
            $table.DataTable().row(':eq(0)').select();
        }
    }).on('xhr', debounce(500, function (e) {
        let $table = $(e.target);
        let $trcTarget = $($table.data('trc-target'));

        addTotalRecordsToTableHeader($(this), $trcTarget);

        if ($table.is(':visible')) {
            let $dt = $table.DataTable();

            if (!$dt.rows('.selected').any()) {
                if ($dt.data().any()) {
                    $table.DataTable().row(':eq(0)').select();
                } else {
                    $('#ajax-panel').empty();
                }
            }
        }
    })).on('key-focus', function (e, datatable, cell, originalEvent) {
        if (originalEvent.type === 'keydown') {
            if (originalEvent.originalEvent.keyCode === 38 || originalEvent.originalEvent.keyCode === 40) {
                datatable.row(cell.index().row).select();
            }
        }
    });
});

window.closeModal = function () {
    var $modalBackdrops = $('.modal-backdrop');
    var $ajaxModals = $('.ajax-modal');

    $modalBackdrops.first().remove();
    $ajaxModals.first().remove();

    // if (!$ajaxModals.length) {
    $('body').removeClass('modal-open').css('padding-right', '0px');
    // }
};

window.getAjaxPanelContent = function (url, params = {}) {
    $.get(url, params,function (data) {
        $('#ajax-panel').html(data);
    });
};

window.addTotalRecordsToTableHeader = function ($table, $target) {
    var recordsTotal = $table.DataTable().page.info().recordsDisplay;
    $target.text(recordsTotal);
};

window.getFiltersDataForDataTable = function ($table) {
    var $filters = $table.closest('.JS-datatable-common-parent').find('.JS-datatable-filters').find('select, input, radio');
    var data = {};

    $.each($filters, function (k, v) {
        var $filter = $(this);
        var name = $filter.attr('name');
        var val;

        if ($filter.is(':checkbox')) {
            val = $filter.is(':checked') ? 1 : 0;
        } else {
            val = $filter.val();
        }

        if ($filter.is(':radio')) {
            val = $('input[name="range"]:checked').val();
        }

        data[name] = val;
    });

    return data;
};

window.highLightRowAndLoadItemForDataTable = function($table) {
    var $selectedRow = $table.find('tbody tr[data-id].selected');

    if (!$selectedRow.length) {
        $selectedRow = $table.find('tbody tr[data-id]:first');
        $selectedRow.addClass('selected');
    }

    if ($table.closest('.tab-pane.active').length) {
        var $accordion = $table.closest('.card.JS-dt-common-parent');

        if ($accordion.length) {
            if ($accordion.find('.cs-accordion-collapse[aria-expanded="true"]').length) {
                $selectedRow.trigger('click');
            }
        } else {
            $selectedRow.trigger('click');
        }
    }
};

window.checkIfAnyRowSelectedAndSelectFirst = function($table) {
    var dataTable = $table.DataTable();

    if ($table.is(':visible') && !dataTable.rows('.selected').any()) {
        dataTable.row(':eq(0)').select();
    }
};

//todo
/*window.postRequest = function(url, doneCallback, failCallback, alwaysCallback) {
    if ($.isPlainObject(url)) {

    }

    $.post(url, data).done(function (r) {
        Msg.success(r.msg);
        if ('script' in r) eval(r.script);
        if (typeof doneCallback !== 'function') eval(doneCallback);
    }).fail(function (r) {
        Msg.error(r.responseJSON.msg);
        if (typeof failCallback !== 'function') eval(failCallback);
    }).always(function (r) {
        if (typeof alwaysCallback !== 'function') eval(alwaysCallback);
    });
};*/

window.checkAndResizeTableHeaders = function () {
    const $visibleTableWrappers = $('.dataTables_wrapper:visible');

    $.each($visibleTableWrappers, function (k, v) {
        const $this = $(this);
        const $thead = $this.find('.dataTables_scrollHead thead');
        const $tbody = $this.find('.dataTables_scrollBody tbody');

        if ($thead.width() !== $tbody.width()) {
            const $dataTable = $this.find('.JS-datatable').DataTable();

            if ($this.hasClass('dts')) {
                $dataTable.scroller.measure();
            } else {
                $dataTable.columns.adjust();
            }
        }
    });
}

window.dtInstantiateMassCheckboxAction = function (dtSelector, clearBtnSelector = '#JS-clear-count', submitBtnSelector = '#JS-action-checked') {
    let checkedItems = [];
    let $dataTable = $(dtSelector);
    let $clearCountBtn = $(clearBtnSelector);
    let $mapCheckedBtn = $(submitBtnSelector);

    function clearCheckedItems() {
        checkedItems = [];
        $mapCheckedBtn.addClass('disabled').attr('disabled', true);
        $clearCountBtn.text(checkedItems.length);
    }

    $clearCountBtn.on('click', function (e) {
        clearCheckedItems();
        $dataTable.DataTable().ajax.reload();
    });

    $mapCheckedBtn.on('click', function (e) {
        e.preventDefault();
        let $linkBtn = $(this);

        postRequestFromBtn($linkBtn, {
            data: {checked_items: checkedItems},
            success: function () {
                $dataTable.DataTable().ajax.reload();
                clearCheckedItems();
            }
        });
    });

    $dataTable.DataTable().on('draw', function (e) {
        $(e.target).find('.JS-check-item').each(function () {
            let $thisDrawnCheckbox = $(this);
            let value = $thisDrawnCheckbox.val();

            if ($.inArray(value, checkedItems) !== -1) {
                $thisDrawnCheckbox.prop('checked', true);
            }
        });
    }).on('click', '.JS-check-item', function (e) {
        e.stopPropagation();

        const $thisClickedCheckbox = $(e.target);
        const val = $thisClickedCheckbox.val();
        const isChecked = $thisClickedCheckbox.is(':checked');
        const isInCheckedItems = $.inArray(val, checkedItems) !== -1;

        if (isChecked && !isInCheckedItems) {
            checkedItems.push(val);
        } else if (!isChecked && isInCheckedItems) {
            checkedItems.splice($.inArray(val, checkedItems), 1);
        }

        $clearCountBtn.text(checkedItems.length);

        if (checkedItems.length) {
            $mapCheckedBtn.removeClass('disabled').attr('disabled', false);
        } else {
            $mapCheckedBtn.addClass('disabled').attr('disabled', true);
        }
    });
};

// ajax page
$(document).on('click', '[data-toggle="ajaxPage"]', function(e) {
    $.fn.dataTable.ext.errMode = 'none';
    e.preventDefault();
    e.stopImmediatePropagation();

    var $this = $(this);
    var remote = $this.attr('href');

    $.ajax({
        url: remote,
        type: 'GET',
        contentType: false,
        cache: false,
        processData: false,
        success: function (result) {
            $('#ajaxPage').html(result);
            window.history.pushState({page: ''}, $(this).data('trigger'), remote);
            /*var $script = $('#ajaxPage').find('script').first();
            if ($script.length) {
                eval($script.html());
            }*/
        }
    });

    if ($this.parent().hasClass('site-menu-item')) {
        $('.site-menu-item').removeClass('active');
        $this.parent().addClass('active');
    }
});

$(document).on('click', '[data-toggle="menuItems"]', function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    const $this = $(this);
    const remote = $this.attr('href');
    const link = $this.attr('data-link');
    const $remoteTarget = $('[data-target="' + remote + '"]');

    $('.nav-link').removeClass('active');
    $this.addClass('active');

    $('.site-menu').hide();
    $remoteTarget.show();

    //load default page
    $.ajax({
        url: link,
        type: 'GET',
        contentType: false,
        cache: false,
        processData: false,
        beforeSend: function() {
            $("#loading").show();
        },
        success: function (result) {
            $('#ajaxPage').html(result);
            window.history.pushState({page: ''}, $(this).data('trigger'), link);

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });

            /*var $script = $('#ajaxPage').find('script').first();
            if ($script.length) {
                eval($script.html());
            }*/
        },
        complete: function () {
            //$("#loading").fadeOut(500);
        }
    });

    $('.site-menu-item').removeClass('active');
    $remoteTarget.find('[href="' + link + '"]').parent().addClass('active');
});

// ajax modal
$(document).on('click', '[data-toggle="ajaxModal"]', function (e) {
    closeModal();
    e.preventDefault();

    //var zIndex = 1500 + $('.ajax-modal').length;
    var $this = $(this);
    var $remote = $this.data('remote') || $this.attr('href');
    var $modal = $('<div class="modal fade modal-fade-in-scale-up ajax-modal"></div>');//.css('z-index', zIndex);

    $this.addClass("disabled");
    $('body').prepend($modal);
    $modal.modal();

    $modal.append('<div style="left:50%; top:50%;" class="loader vertical-align-middle loader-ellipsis"></div>').load($remote, function () {
        $this.removeClass("disabled");

        //$('.ajax-modal .JS-datepicker').datepicker($.extend(true, {}, window.defaultDatepickerOptions, {}));
        $('.ajax-modal .JS-select2').select2();
        $('.ajax-modal [data-toggle="tooltip"]').tooltip();

        var $modalScript = $('.modal-body').find('script').first();
        if ($modalScript.length) {
            eval($modalScript.html());
        }
    });
});

$(document).on('mouseover', '[data-toggle="ajaxModalHover"]', function (e) { // fixme not used
    closeModal();
    e.preventDefault();

    $(this).addClass("disabled");

    var $this = $(this)
        , $remote = $this.data('remote') || $this.attr('href')
        ,
        $modal = $('<div class="modal fade modal-fade-in-scale-up" id="ajaxModal"><div class="modal-body"></div></div>');
    $('body').prepend($modal);
    $modal.modal();

    $('.modal .modal-body').append('<div style="left:50%; top:50%;" class="loader vertical-align-middle loader-ellipsis"></div>');
    $('.modal .modal-body').load($remote, function () {
        $this.removeClass("disabled");
        Ladda.bind('button[type=submit]');

        var elems = Array.prototype.slice.call(document.querySelectorAll('[data-plugin="switchery"]'));

        elems.forEach(function (html) {
            var switchery = new Switchery(html, {size: 'large', color: 'rgb(62, 142, 247)'});
        });
        $(".selectpicker").selectpicker({style: "btn-select", iconBase: "icon", tickIcon: "wb-check"});

    });
});

$(document).keyup(function (e) {
    if (e.key === "Escape") { // escape key maps to keycode `27`
        closeModal();
    }
});

$(document).on('click', '[data-dismiss="modal"]', function (e) {
    closeModal();
});

$(document).ready(function(){
    if ($.fn.popover) {
        $('[data-toggle="popover"]').popover({
            trigger: 'hover',
            placement: 'bottom',
            delay: 0
        });
    }
});

$(document).on('submit', '[data-toggle="ajaxForm"]', function (e) {
    e.preventDefault();
    var $form = $(this);
    var $submitBtn = $form.find('button[type="submit"]');
    var callbackAttr = $form.data('callback');

    $submitBtn.addClass('disabled').attr('disabled', true);

    $.post($form.attr('action'), $form.serialize()).done(function (r) {
        Msg.success(r.msg);
        if ('script' in r) eval(r.script);
        if (typeof callbackAttr !== 'undefined') eval(callbackAttr);
    }).fail(function (r) {
        Msg.error(r.responseJSON.msg);
    }).always(function () {
        $submitBtn.removeClass('disabled').attr('disabled', false);
    });
});

/*$(document).on('submit', '[data-toggle="ajaxForm"]', function (e) {
    e.preventDefault();

    var formElem = $(this);
    var this_form = new FormData(formElem[0]);
    var this_form_url = $(this).attr('action');
    var form_alert = $(this).parent().closest('.modal-dialog, .JS-send-note-form').find('.modal-alert');

    $.ajax({
        url: this_form_url,
        type: 'POST',
        contentType: false,
        cache: false,
        processData: false,
        data: this_form,
        success: function (result) {
            form_alert.html(result);
        }
    });
});*/

$(document).on('submit', '[data-toggle="pageAjaxForm"]', function (e) {
    e.preventDefault();

    var formElem = $(this);
    var this_form = new FormData(formElem[0]);
    var this_form_url = $(this).attr('action');
    var form_alert = $(this).find('.modal-alert');

    $.ajax({
        url: this_form_url,
        type: 'POST',
        contentType: false,
        cache: false,
        processData: false,
        data: this_form,
        success: function (result) {
            form_alert.html(result);
        }
    });
});

$(document).on('submit', '[data-toggle="ajaxFormPop"]', function (e) {
    e.preventDefault();

    var formElem = $(this);
    var this_form = new FormData(formElem[0]);
    var this_form_url = $(this).attr('action');
    var form_alert = $(this).parent().find('.modal-alert');

    $.ajax({
        url: this_form_url,
        type: 'POST',
        contentType: false,
        cache: false,
        processData: false,
        data: this_form,
        success: function (result) {
            closeModal();
            e.preventDefault();
            var $this = $(this),
                $modal = $('<div class="modal fade modal-fade-in-scale-up ajax-modal"><div class="modal-body"></div></div>');
            $('body').prepend($modal);
            $modal.modal();

            $('.modal .modal-body').html(result);
            //Ladda.bind('button[type=submit]');
            $('.datepicker').datepicker($.extend(true, {}, window.defaultDatepickerOptions, {}));
            $('.JS-select2').select2($.extend(true, {}, window.defaultSelect2Options, {}));
            $('[data-toggle="tooltip"]').tooltip();
        }
    });
});

$(document).on('click', '.JS-nav-link', debounce(500, function () {
    var $this = $(this);
    var $tabPane = $($this.attr('href'));

    if ($tabPane.length > 0) {
        var $dataTable = $tabPane.find('.dataTable').DataTable();
        var $selectedRow = $tabPane.find('.dataTable tbody tr.selected');

        if (!$selectedRow.length) {
            $selectedRow = $tabPane.find('.dataTable tbody tr:first-child');
        }
        if ($selectedRow.length) {
            $dataTable.row($selectedRow).select();
        }
    }
}));

$(document).on('keydown', '.JS-send-note-form textarea[name="description"]', function (e) {
    if(e.keyCode === 13){
        e.preventDefault();
        $(this).next('button[type="submit"]').trigger('click');
    }
});

$(document).on('keyup change', '.JS-datatable-filters input, .JS-datatable-filters select', debounce(500, function () {
    var $this = $(this);
    var $dataTable = $this.closest('.JS-datatable-common-parent').find('.JS-datatable');
    if ($dataTable) {
        if ($this.hasClass('JS-datatable-search')) {
            $dataTable.DataTable().search(this.value);
        }

        $dataTable.DataTable().ajax.reload();
    }
}));

$(document).on('keyup', '.JS-datatable-search', debounce(500, function () {
    var $table = $(this).closest('.JS-datatable-common-parent').find('.JS-datatable');
    $table.unmark();
}));

$(document).on('draw.dt', '.JS-datatable', function () {
    var $table = $(this);
    var $searchInput = $table.closest('.JS-datatable-common-parent').find('.JS-datatable-search');

    if ($searchInput.length) {
        $table.mark($searchInput.val(), {
            "accuracy": {
                "value": "partially",
                "limiters": [",", "."]
            }
        });
    }
});

$(document).on('keyup', '.select2-search__field', debounce(500, function () {
    var $searchInput = $(this);
    var $select2Results = $('.select2-results');
    $select2Results.unmark();

    if ($searchInput.length) {
        $select2Results.mark($searchInput.val(), {
            "accuracy": {
                "value": "partially",
                "limiters": [",", "."]
            }
        });
    }
}));

$(document).on('click', 'input[type="checkbox"].JS-datatable-massaction-check-all', function () {
    var $thisCheckbox = $(this);
    var $commonParent = $thisCheckbox.closest('.JS-datatable-common-parent');
    var $checkedCount = $commonParent.find('.JS-datatable-massaction-checked-count');
    var $checkboxes = $thisCheckbox.closest('.JS-datatable-common-parent').find('.JS-datatable-massaction-id');

    $checkboxes.prop('checked', $thisCheckbox.is(':checked'));
    $checkedCount.text($('.JS-datatable-massaction-id:checked').length);
});

$(document).on('click', '.JS-datatable-massaction-id', function () {
    var $thisCheckbox = $(this);
    var $commonParent = $thisCheckbox.closest('.JS-datatable-common-parent');
    var $checkedCount = $commonParent.find('.JS-datatable-massaction-checked-count');

    $checkedCount.text($('.JS-datatable-massaction-id:checked').length);
});

$(document).on('click', '.cs-accordion-collapse', debounce(500, function () {
    var $this = $(this);
    var $parent = $this.closest('.JS-dt-common-parent');

    if (!$this.hasClass('collapsed')) {
        var $dataTable = $parent.find('.dataTable').DataTable();
        var $selectedRow = $parent.find('.dataTable tbody tr.selected');

        if (!$selectedRow.length) {
            $selectedRow = $parent.find('.dataTable tbody tr:first-child');
        }
        if ($selectedRow.length) {
            $dataTable.row($selectedRow).select();
        }
    }
})).on('click', '.JS-nav-link', debounce(500, function () {
    var $this = $(this);
    var $tabPane = $($this.attr('href'));
    var $accordion = $tabPane.find('.cs-accordion-collapse[aria-expanded="true"]');
    var $parent = $accordion.closest('.JS-dt-common-parent');

    if ($parent.length > 0) {
        var $dataTable = $parent.find('.dataTable').DataTable();
        var $selectedRow = $parent.find('.dataTable tbody tr.selected');

        if (!$selectedRow.length) {
            $selectedRow = $parent.find('.dataTable tbody tr:first-child');
        }
        if ($selectedRow.length) {
            $dataTable.row($selectedRow).select();
        }
    }
})).on('click', '.JS-nav-link-returns', debounce(500, function () {
    var $this = $(this);
    var $tabPane = $($this.attr('href'));

    if ($tabPane.length > 0) {
        var $dataTable = $tabPane.find('.dataTable').DataTable();
        var $selectedRow = $tabPane.find('.dataTable tbody tr.selected');

        if (!$selectedRow.length) {
            $selectedRow = $tabPane.find('.dataTable tbody tr:first-child');
        }
        if ($selectedRow.length) {
            $dataTable.row($selectedRow).select();
        }
    }
})).on('keydown', '.JS-send-note-form textarea[name="note"]', function (e) {
    if(e.keyCode === 13){
        e.preventDefault();
        $(this).next('button[type="submit"]').trigger('click');
    }
});

$(document).on('click', '.JS-datatable-export-btn', function (e) {
    e.preventDefault();
    var $this = $(this);
    var $dataTable = $($this.data('target'));

    if ($dataTable) {
        $this.addClass('disabled');
        $dataTable.DataTable().button(0).trigger();
        $this.removeClass('disabled');
    }
});

$(document).on('click', '.JS-post-request-from-btn', function (e) {
    e.preventDefault();
    postRequestFromBtn($(this));
}).on('click', '.JS-unmap-listing-from-item', function (e) { //todo move al success callbacks on the button or controller
    e.preventDefault();
    postRequestFromBtn($(this), { success: function () { $('#dt-mapped, #dt-unmapped').DataTable().ajax.reload(); }});
}).on('click', '.JS-unmap-vitem-from-item', function (e) {
    e.preventDefault();
    postRequestFromBtn($(this), { success: function () { $('#dt-mapped, #dt-unmapped').DataTable().ajax.reload(); }});
}).on('click', '.JS-exclude-mapping, .JS-map-one', function (e) {
    e.preventDefault();
    var $linkBtn = $(this);
    var data = {item_id: $linkBtn.data('item-id'), jolt_listing_id: $linkBtn.data('jolt-listing-id')};

    postRequestFromBtn($linkBtn, {
        data: data,
        success: function () {
            $('#dt-mapping, #dt-mapped,#dt-excluded').DataTable().ajax.reload();
        }
    });
}).on('click', '.JS-mapping-action, #JS-map-available', function (e) {
    e.preventDefault();

    if (($(this).attr('id') === 'JS-map-available') && !confirm('Are you sure? This will map all the listings!')) {
        return false;
    }

    postRequestFromBtn($(this), { success: function () { $('#dt-mapping, #dt-mapped, #dt-excluded').DataTable().ajax.reload(); }});
}).on('shown.bs.tab', '.nav-link', checkAndResizeTableHeaders).on('click', '.JS-expand-table-btn', checkAndResizeTableHeaders);

function baseRequest (url, opts = {}) {
    $.ajax($.extend(true, {}, opts, {
        url: url,
        success: function (r) {
            Msg.success(r.msg);
            // if ('script' in r) eval(r.script);
            if (opts.hasOwnProperty('success') && (typeof opts.success === 'function')) opts.success();
        },
        error: function (r) {
            Msg.error(r.responseJSON.msg);
            // if ('script' in r) eval(r.responseJSON.script);
            if (opts.hasOwnProperty('error') && (typeof opts.error === 'function')) opts.error();
        },
        complete: function (r) {
            if ('script' in r.responseJSON) eval(r.responseJSON.script);
            if (opts.hasOwnProperty('complete') && (typeof opts.complete === 'function')) opts.complete();
        }
    }));
}

window.postRequest = function (url, opts = {}) {
    baseRequest(url, $.extend(true, {}, opts, {method: 'post'}));
}

window.getRequest = function (url, opts = {}) {
    baseRequest(url, $.extend(true, {}, opts, {method: 'get'}));
}

function baseRequestFromBtn ($btn, opts = {}) {
    let $linkBtn = $btn;
    $linkBtn.addClass('disabled').attr('disabled', true);

    baseRequest($linkBtn.attr('href'), $.extend(true, {}, opts, {
        error: function () {
            $linkBtn.removeClass('disabled').attr('disabled', false);
            if (opts.hasOwnProperty('error') && (typeof opts.error === 'function')) opts.error();
        }
    }));
}

window.postRequestFromBtn = function ($btn, opts = {}) {
    baseRequestFromBtn($btn, $.extend(true, {}, opts, {method: 'post'}));
}

window.getRequestFromBtn = function ($btn, opts = {}) {
    baseRequestFromBtn($btn, $.extend(true, {}, opts, {method: 'get'}));
}

$('.has-clear input[type="text"]').on('input propertychange', function() {
    var $this = $(this);
    var visible = Boolean($this.val());
    $this.siblings('.form-control-clear').toggleClass('d-none', !visible);
}).trigger('propertychange');

$('.form-control-clear').click(function() {
    $(this).siblings('input[type="text"]').val('')
        .trigger('propertychange').focus();
});

$(document).on('submit', '.JS-sfi-form', function (e) {
    e.preventDefault();
    var $this = $(this);
    var $fileInput = $this.find('input[type=file]').first();

    $.ajax({
        type: 'POST',
        url: $this.attr('action'),
        data: new FormData(this),
        dataType: 'json',
        contentType: false,
        cache: false,
        processData:false,
        beforeSend: function () {
            $fileInput.attr("disabled", "disabled");
        },
        success: function (r) {
            Msg.success(r.msg);
        },
        error: function (r) {
            Msg.error(r.responseJSON.msg);
        },
        complete: function (r) {
            $fileInput.removeAttr("disabled");
            if ('script' in r.responseJSON) eval(r.responseJSON.script);
        }
    });
}).on('change', '.JS-sfi-input',function() {
    var $this = $(this);
    var fileName = $this.val().split("\\").pop();

    $this.siblings(".custom-file-label").addClass("selected").html(fileName);
    $this.closest('form').submit();
});



/* Old Code Start */

//todo check if redundant
window.refreshAllDataTables = function () {
    $.each($('.dataTable'), function (i) {
        $(this).DataTable().ajax.reload(null, false);
    });
};

//todo check if redundant
window.loadPageContent = function (url, dest, callback) {
    $('#' + dest).html('<div class="example-loading example-well vertical-align text-center">\n' +
        '                    <div class="loader vertical-align-small loader-round-circle"></div>\n' +
        '                  </div>');
    $.get(url, function (data) {
        $('#' + dest).html(data);
        //window.setTimeout(update, 10000);
    });

    if (callback) {
        callback();
    }
};

//todo check if redundant
window.openUrlModal = function (url) {
    $('<a href="' + url + '" data-toggle="ajaxModal"></a>').appendTo("body").click().remove();
};

/* Old Code End */
